import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2016-01-01T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "http://ohlouise.co.uk/"
      }}><img parentName="a" {...{
          "src": "/reading/the-crack-in-space.jpg",
          "alt": "The Crack in Space illustration by Jerome Podwill"
        }}></img></a></p>
    <img alt="The Crack in Space red spaceship cover art" width="52.35%" src="/reading/the-crack-in-space-yellow-cab.jpg" />
    <a href="https://www.pinterest.com/pin/502292164687879480/"><img alt="Fantasy art illustration by Yin Yu" width="47.65%" src="/reading/the-crack-in-space-fantasy-art.jpg" /></a>
    <p><img parentName="p" {...{
        "src": "/reading/the-crack-in-space-covers.png",
        "alt": "The Crack in Space cover art"
      }}></img></p>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`The crack in space`}</strong></li>
      <li parentName="ul">{`Philip K Dick`}</li>
    </ul>
    <p>{`My rating: ★★★★★`}</p>
    <p>{`Did not want this book to end. Is more of a time travel novel than science fiction, definitely nods to The Time Machine. It draws on my (and probably everyone's) curiosity about the evolution of homosapiens—what we evolved to be, could have been, and other versions of humans we could have lived with.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      